import React, { useState } from 'react'
import './App.css'
import { ThemeProvider } from './context/themeContext'
import { StoreProvider } from './context/stopContext'
import { Main } from './components/Main'

function App() {
  return (
    <div className="App">
      <StoreProvider>
        <ThemeProvider>
          <Main />
        </ThemeProvider>
      </StoreProvider>
    </div>
  )
}

export default App
