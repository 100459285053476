import React, { useState, FC, ReactNode, useContext, useReducer, createContext } from 'react';
// import * as React from 'react';
import { Theme } from '../@types/store';

const getThemeState = () => {
  const state = localStorage.getItem("TrashbookThemeState");
  const defaultState = {
    theme: "light",
  }
  return state ? JSON.parse(state) : defaultState;
}

const themeState = getThemeState();

export const ThemeContext = React.createContext<Theme>(themeState);


interface ChildrenProps {
  children?: ReactNode;
}

export const ThemeProvider: FC<ChildrenProps> = ( {children} ) => {
  const [theme, setTheme] = useState(themeState.theme);

  const toggleTheme = () => {
    if(theme === "light") setTheme("dark")
    else setTheme("light");
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
