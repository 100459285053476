import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { EXCEPTION, EXCEPTIONPHOTOS } from '../../firebase-config'
import { limit, orderBy, query, Timestamp, where } from '@firebase/firestore'
import { InfoModal } from './InfoModal'
import { SearchBar } from './SearchBar'
import './styles.scss'
import { func } from 'prop-types'

export const DataDisplay = () => {
  const [customers, setCustomers] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false)
  const [dataToShowInModal, setDataToShowInModal] = useState(null)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [csvData, setCSVData] = useState<string>("");
  console.log(csvData);

  const handleModalOpen = (data: any) => {
    setDataToShowInModal(data)
    setShowModal(true)
  }

  const queryAllData = () => {
    let dbData: any[] = []

    // Set delay for data on home page to one day behind.
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const q = query(
      collection(db, EXCEPTION),
      where('date', '<', yesterday),
      orderBy('date', 'desc'),
      limit(30),
    )
    getDocs(q)
      .then((res) => {
        res.forEach((doc) => {
          dbData.push({ ...doc.data(), docId: doc.id })
        })
      })
      .then(() => {
        setCustomers(dbData)
        setPageLoaded(true)
      })
  }
  useEffect(() => {
    queryAllData()
  }, [])

  const handleSort = (field: any) => {
    let sortedCustomers = [...customers]
    if (field === 'date')
      sortedCustomers.sort((a, b) => b[field].seconds - a[field].seconds)
    else if (field === 'route')
      sortedCustomers.sort((a, b) => +a[field] - +b[field])
    else
      sortedCustomers.sort((a: string, b: string) =>
        a[field].localeCompare(b[field]),
      )
    setCustomers(sortedCustomers)
  }

  function handleDateChange(e: any) {
    // get timestamp from date input
    const date = new Date(e.target.value);
    const nextDay = new Date(e.target.value);
    nextDay.setDate(nextDay.getDate() + 1);

    let dbData: any[] = [];

    const q = query(
      collection(db, EXCEPTION),
      where('date', '>=', date),
      where('date', '<=', nextDay),
      orderBy('date', 'desc'),
    )
    getDocs(q)
      .then((res) => {
        res.forEach((doc) => {
          dbData.push({ ...doc.data(), docId: doc.id })
        })
      })
      .then(() => {
        setCustomers(dbData)
        setPageLoaded(true)
      })
  }

  function dataToCSV() {
    if (customers.length === 0) return;
    const csvRows = [];
    const headers = ["exception", "date", "address", "notes", "streetNumber", "streetName", "route", "serviced", "photoIDs"];
    csvRows.push(headers.join(','));
    for (const row of customers) {
      const values = headers.map(header => {
        // remove commas from date
        if (header === "date") return new Date(row[header].seconds * 1000).toLocaleString().split(',').join('');
        // remove commas from address
        if (header === "address") return row[header].split(',').join('');
        const escaped = ('' + row[header] || "").replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }
    setCSVData(csvRows.join('\n'));
  }

  function downloadCSV() {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useEffect(() => { dataToCSV() }, [customers])

  useEffect(() => {
    handleSort('date')
  }, [pageLoaded])

  return (
    <div className="data-table-body">
      <InfoModal
        show={showModal}
        hide={() => setShowModal(false)}
        data={dataToShowInModal}
        update={() => queryAllData()}
      />
      <SearchBar
        customers={customers}
        setCustomers={setCustomers}
        reset={queryAllData}
        setLoading={setPageLoaded}
      />

      <label > Select a date to see all reports from the selected date</label>
      <div className="data-button-group">
        <input type='date' className='' onChange={handleDateChange}></input>
        <Button onClick={downloadCSV} className="dowload-csv-button">
          Download CSV
        </Button>
      </div>

      <Table className="data-table" striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('route')}>Route</th>
            <th onClick={() => handleSort('address')}>Address</th>
            <th onClick={() => handleSort('exception')}>Exception</th>
            <th onClick={() => handleSort('date')}>Date</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((stop, i) => {
            const date = new Timestamp(stop.date.seconds, stop.date.nanoseconds)
              .toDate()
              .toLocaleDateString()

            return (
              <tr className={(stop.exception === "abandoned" && !stop.serviced) ? 'table-danger' : ""} key={Math.random()} onClick={() => handleModalOpen(stop)}>
                <td>{stop.route}</td>
                <td>{stop.address.split(',')[0]}</td>
                <td>{stop.exception}</td>
                <td>{date}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
