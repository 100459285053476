import React, { useEffect, useContext, useState } from 'react'
import { Nav, Navbar, Container, Offcanvas } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { auth, db } from '../../firebase-config'
import { signOutUser } from '../../helpers/auth'
import { StoreContext } from '../../context/stopContext'
import { onAuthStateChanged } from '@firebase/auth'
import { doc, getDoc } from '@firebase/firestore'
import logo from '../../images/trashbook-logo.png'
import './styles.scss'

export const NavBar = ({ update }: any) => {
  const { state, dispatch } = useContext(StoreContext)
  const [showDriverControls, setShowDriverControls] = useState(false)
  const navigate = useNavigate()
  const handleSignOut = () => {
    signOutUser()
      .then(() => window.location.reload())
      .catch(() => console.log('An Error Occured'))
  }
  const addDriverToState = (driver: any) => {
    dispatch({
      type: 'ASSIGN_DRIVER',
      payload: {
        id: driver?.id,
        name: driver?.name,
        route: driver?.routeNumber,
        relief: driver?.reliefDriver,
        email: driver?.email || '',
        role: driver?.role || 'GENERAL',
        routeCoords: driver?.routeCoords?.north
          ? driver.routeCoords
          : {
            north: 37.81455,
            south: 37.66559,
            east: -122.35037,
            west: -122.53961,
          },
      },
    })
  }

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'Users', user.uid)
        const userSnap = await getDoc(userRef)
        if (userSnap) {
          const data: any = { ...userSnap.data(), id: user.uid }
          addDriverToState(data)

          if (data.role && (data.role === 'DRIVER' || data.role === 'ADMIN')) {
            navigate('/report')
          }
        } else {
          // no user logic
          console.log('no user')
        }
        update()
      } else console.log('logged out')
    })
    return () => {
      setTimeout(() => {
        let loading = document.getElementById('loading')
        loading?.remove()
      }, 2000)
    }
  }, [])

  return (
    <Navbar fixed="top" bg="light" expand={'md'}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img className="tb-nav-logo" src={logo} alt="trashbook logo" />
          {' '} TrashBook
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={() => setShowDriverControls(true)} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          show={showDriverControls}
          onHide={() => setShowDriverControls(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              Offcanvas
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Item className='mb-3'>
                <Link className="nav-link" to="/" onClick={() => setShowDriverControls(false)}>
                  Home
                </Link>
              </Nav.Item>
              {state?.driver?.role === 'DRIVER' ||
                state?.driver?.role === 'ADMIN' ? (
                <Nav.Item className='mb-3'>
                  <Link className="nav-link" to="/report" onClick={() => setShowDriverControls(false)}>
                    Report
                  </Link>
                </Nav.Item>
              ) : null}
              { state?.driver?.role === 'ADMIN' ? (
                  <Nav.Item className='mb-3'>
                    <Link className="nav-link" to="/viewlog" onClick={() => setShowDriverControls(false)}>
                      ViewLog
                    </Link>
                  </Nav.Item>
                ) : null}
              <Nav.Item className='mb-3'>
                {auth.currentUser ? (
                  <a className="nav-link" onClick={handleSignOut}>
                    {' '}
                    Sign out
                  </a>
                ) : (
                  <Link className="nav-link" to="/login" onClick={() => setShowDriverControls(false)}>
                    Login
                  </Link>
                )}
              </Nav.Item>
              <Nav.Item className='mb-3'>
                <Link className="nav-link" to="/feedback" onClick={() => setShowDriverControls(false)}>
                  Contact
                </Link>
              </Nav.Item>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
