import React, { useContext, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import './styles.scss'
import { StoreContext } from '../../../context/stopContext'
import { doc, deleteDoc, setDoc, collection } from '@firebase/firestore'
import { db, EXCEPTION, VIEW_LOGS } from '../../../firebase-config'
import { GeoPoint } from '@firebase/firestore'
import axios from 'axios'
import { auth } from '../../../firebase-config'
import { getStorage, ref, deleteObject } from "firebase/storage";
import { EXCEPTIONPHOTOS } from '../../../firebase-config'

export const InfoModal = ({ show, hide, data, update }: any) => {
  const { state, dispatch } = useContext(StoreContext)
  const driverID = state.driver?.id || ''
  const reportID = data?.driverID || ''
  const showPickupButton = (state.driver?.role === 'ADMIN' || state.driver?.role === 'DRIVER' || state.driver?.role === 'SUPERVISOR') && !data?.serviced && data?.exception === 'abandoned'
  
  const handleDelete: () => void = async () => {
    if(data.photoRefs.length) {
      const storage = getStorage();
      data.photoRefs.forEach(async (photoRefId: string) => {
        const photoRef = ref(storage, `${EXCEPTIONPHOTOS}/${photoRefId}`);
        await deleteObject(photoRef);
      })
    }
    await deleteDoc(doc(db, EXCEPTION, data.docId))
    hide()
    update()
  }

  const triggerViewLog = async () => {
    const user = auth.currentUser?.email || "";
    if(user === "benn925@yahoo.com") return;
    const date = new Date()
    const ip = await axios.get("https://api.ipify.org/?format=json");
    let lat = 0, lng = 0;
    navigator.geolocation.getCurrentPosition(function(position) {
      lat = position.coords.latitude
      lng = position.coords.longitude
    });
    const viewData = {
      location: new GeoPoint(lat, lng),
      address: data.address,
      id: data.docId,
      ip: ip.data.ip,
      user: user,
      time: date
    }
    const viewRef = doc(db, VIEW_LOGS, `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}-${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);
    setDoc(viewRef, viewData, { merge: true });
  }

  function handleAbandonedPickup() {
    data.serviced = true;
    const abandonedRef = doc(db, EXCEPTION, data.docId);
    setDoc(abandonedRef, {serviced: true}, {merge: true});
    hide();
  }

  useEffect(()=> {
    show && triggerViewLog();
  },[data, show])

  return (
    data && (
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{data.address.split(',')[0]}</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <p>
            <strong>Date:</strong>{' '}
            {new Date(data.date.seconds * 1000).toLocaleString()}
          </p>
          <p>
            <strong>Route:</strong> {data.route}
          </p>
          <hr />
          <p>
            <strong>Reason:</strong> {data.exception}
          </p>
          <p>
            <strong>Serviced:</strong> {data.serviced ? 'Yes' : 'No'}
          </p>
          <p>
            <strong>Notes:</strong>
          </p>
          <p>{data.notes}</p>
          <hr />
          {data.photoIDs.length
            ? data.photoIDs.map((src: string) => {
                return (
                  <img
                    key={Math.random()}
                    src={src}
                    alt="trash-img"
                    className="modal-img"
                  />
                )
              })
            : ''}
          {driverID === reportID && (
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
          {showPickupButton && (
            <Button
              variant="success"
              onClick={() => {
                handleAbandonedPickup()
              }}
            >
              Mark as picked up
            </Button>
          )}
        </div>
      </Modal>
    )
  )
}
