import React, { useEffect, useState, useContext } from 'react'
import { Timestamp } from '@firebase/firestore'
import { Modal, Button, Form } from 'react-bootstrap'
import { BsCamera } from 'react-icons/bs'
import { AiOutlineUpload } from 'react-icons/ai'
import Autocomplete from 'react-google-autocomplete'
import { Camera } from './Camera'
import { db, EXCEPTION, EXCEPTIONPHOTOS } from '../../../firebase-config'
import { doc, addDoc, collection } from 'firebase/firestore'
import { StoreContext } from '../../../context/stopContext'
import { GeoPoint } from '@firebase/firestore'

import {
  ref,
  uploadBytes,
  getDownloadURL,
  getStorage,
  uploadString,
  deleteObject,
} from '@firebase/storage'
import { permuteForKeywords } from '../../../helpers/helperFunctions'
import './styles.scss'

export const ReportModal = ({ show, setShow, exception }: any) => {
  const { state, dispatch } = useContext(StoreContext)
  const [uploading, setUploading] = useState(false)
  const [photos, setPhotos] = useState<any[]>([])
  const [photoIDs, setPhotoIDs] = useState<any[]>([])
  const [photoRefs, setPhotoRefs] = useState<any[]>([])
  const [address, setAddress] = useState<any>({ formatted_address: '', geometry: { location: null } })
  const [notes, setNotes] = useState('')
  const [serviced, setServiced] = useState(true)
  const [showCamera, setShowCamera] = useState(false)
  const [streetNum, setStreetNum] = useState('')
  const [streetName, setStreetName] = useState('')
  const [addressComponents, setAddressComponents] = useState([])
  const [photoRefsToCancel, setPhotoRefsToCancel] = useState<any[]>([])

  const reportSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const stateReport = {
      uploading: uploading,
      photos: photos,
      photoIDs: photoIDs,
      photoRefs: photoRefs,
      address: address.formatted_address,
      notes: notes,
      serviced: serviced,
      streetNum: streetNum,
      streetName: streetName,
    }
    const dbData = {
      driver: state.driver?.name || '',
      driverID: state.driver?.id || null,
      address: address.formatted_address,
      addressKeywords: permuteForKeywords([
        address.formatted_address.split(',')[0],
      ]),
      streetNumber: streetNum,
      streetName: streetName,
      notes,
      photoIDs,
      photoRefs,
      serviced,
      route: state.driver?.route || null,
      exception,
      date: Timestamp.now(),
      geoPoint: address.geometry?.location ? new GeoPoint(address.geometry.location.lat(), address.geometry.location.lng()) : null,
    }
    addDoc(collection(db, EXCEPTION), dbData)
    addDoc(collection(db, "BUGREPORT"), stateReport)
    handleClose()
  }

  const uploadPhotos = async () => {
    setUploading(true)
    const storage = getStorage()
    const tempPhotoIds: any[] = []

    await Promise.all(photos.map(
      async (photo): Promise<any> => {
        const newID = Date.now() + Math.floor(Math.random() * 1000)
        const imageRef = ref(storage, `${EXCEPTIONPHOTOS}/${newID}`)
        setPhotoRefsToCancel((prev) => [...prev, imageRef])
        if (typeof photo === 'string') {
          await uploadString(imageRef, photo, 'data_url').then(
            async (snapshot) => {
              await getDownloadURL(snapshot.ref).then((url) => {
                tempPhotoIds.push(url)
              })
            },
          )
        } else {
          await uploadBytes(imageRef, photo).then(async (snapshot) => {
            await getDownloadURL(snapshot.ref).then((url) => {
              tempPhotoIds.push(url)
            })
          })
        }
        setPhotoRefs((prev) => [...prev, newID])
      },
    )).then(() => {
      setPhotoIDs(prev => [...prev, ...tempPhotoIds])
    })
    setUploading(false)
  }

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files: any[] = []
      for (let key in Object.keys(event.target.files))
        files.push(event.target.files[key])
      setPhotos((prevPhotos) => [...prevPhotos, ...photos, ...files])
      console.log('uploaded')
    }
  }
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)

  const handleInputOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (fileInputRef && fileInputRef.current) fileInputRef.current.click()
  }

  const handleCancel = () => {
    photoRefsToCancel.map((ref) => deleteObject(ref))
    handleClose()
  }

  const handleClose = () => {
    setPhotos([])
    setPhotoIDs([])
    setAddress({ formatted_address: '', geometry: { location: null } })
    setNotes('')
    setShow(false)
    setServiced(true)
    setPhotoRefsToCancel([])
    setPhotoRefs([])
  }

  useEffect(() => {
    if (exception === 'not out' || exception === 'abandoned') {
      setServiced(() => false)
    }
  }, [exception])

  useEffect(() => {
    if (photos.length) {
      uploadPhotos()
    }
  }, [photos])

  useEffect(() => {
    setPhotos(() => [])
  }, [photoIDs])

  useEffect(() => {
    let components = address.formatted_address.split(',')
    let streetAddress = components.shift()?.split(' ')
    setStreetNum(streetAddress?.shift() || '')
    setStreetName(streetAddress?.join(' ') || '')
  }, [address])

  return (
    <Modal show={show} onHide={handleCancel} keyboard={false}>
      <Form onSubmit={reportSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{exception.toUpperCase()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="display-box-modal">
            <h3>Photos: {photoIDs.length}</h3>
          </div>
          <div className="display-box-modal">
            <Button variant="primary" onClick={handleInputOpen}>
              <BsCamera />
            </Button>
            <input
              onChange={handleUpload}
              multiple={true}
              ref={fileInputRef}
              type="file"
              accept="image/*"
              hidden
            />
          </div>
          <div className="display-box-modal">
            <Autocomplete
              className="form-control"
              id="autocomplete"
              apiKey={'AIzaSyAmVo3x5tWgsZRsC34Ir5tIVhMX7azWaiM'}
              onPlaceSelected={(place: any) => setAddress(place)}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              onChange={(e) => {
                setAddress({
                  formatted_address: (e.target as HTMLInputElement).value,
                  geometry: { location: null }
                })
              }}
              placeholder="Enter an address"
              options={{
                types: ['address'],
                componentRestrictions: { country: 'us' },
                bounds: state.driver?.routeCoords || {
                  north: 37.81455,
                  south: 37.66559,
                  east: -122.35037,
                  west: -122.53961,
                },
                strictBounds: true,
              }}
            />
          </div>
          <div className="display-box-modal">
            <Form.Control
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              placeholder="Notes or details..."
              as="textarea"
              aria-label="With textarea"
              rows={10}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                checked={serviced}
                onChange={() => setServiced(!serviced)}
                label="Customer Serviced?"
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCancel}>
            CANCEL
          </Button>
          <Button variant="primary" type="submit" disabled={uploading}>
            SUBMIT
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
