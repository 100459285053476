import React, { useState } from 'react'
import './styles.scss'
import { IoTrashBinOutline } from 'react-icons/io5'
import { GiCardboardBox } from 'react-icons/gi'
import { TbTrashOff } from 'react-icons/tb'
import { BsQuestionDiamond } from 'react-icons/bs'
import { HiOutlineTrash } from 'react-icons/hi'
import { ReportModal } from './ReportModal'

export const Report = () => {
  const [showModal, setShowModal] = useState(false);
  const [exception, setException] = useState("");

  const handleExceptionClick = (reason: string) => {
    setException(reason);
    setShowModal(true);
  }

  return (
    <div className="report-container">
      <div className="button-group">
        <div className="photo-container display-box" onClick={() => handleExceptionClick("contamination")}>
          <IoTrashBinOutline className="bi" />
          <h3>Contamination</h3>
        </div>
        <div className="display-box" onClick={() => handleExceptionClick("extra")}>
          <GiCardboardBox className="bi" />
          <h3>Extra</h3>
        </div>
        <div className="display-box" onClick={() => handleExceptionClick("not out")}>
          <TbTrashOff className="bi" />
          <h3>Not Out</h3>
        </div>
        <div className="display-box" onClick={() => handleExceptionClick("other")}>
          <BsQuestionDiamond className="bi" />
          <h3>Other</h3>
        </div>

        <div className="display-box" onClick={() => handleExceptionClick("abandoned")}>
        <HiOutlineTrash className="bi" />
          <h3>Abandoned</h3>
        </div>

        <div className="display-box" onClick={() => handleExceptionClick("serviced")}>
          <i className="bi bi-check"></i>
          <h3>Serviced</h3>
        </div>
        {/* <div className="display-box" onClick={() => handleExceptionClick("cart Service")}>
          <HiOutlineTrash className="bi" />
          <h3>Cart Service</h3>
        </div> */}
        <ReportModal show={showModal} setShow={setShowModal} exception={exception} />
      </div>
    </div>
  )
}
