import React, { useEffect, useState } from 'react'
import { Form, Button, Dropdown } from 'react-bootstrap'
import {
  collection,
  getDocs,
  where,
  query,
  collectionGroup,
} from 'firebase/firestore'
import { EXCEPTION, db } from '../../../firebase-config'
import './styles.scss'

export const SearchBar = ({ customers, setCustomers, reset, setLoading }: any) => {
  const [filterParam, setFilterParam] = useState('address')
  const [filterString, setFilterString] = useState('')

  const handleFilter = async () => {
    if (filterParam === 'Search By') return reset()
    if (!filterString.length) return reset()
    setLoading(false);
    let filteredCustomer: any[] = []
    let dataIDs = new Set();
    const customerQuery = query(
      collectionGroup(db, EXCEPTION),
      where(filterParam, '>=', filterString),
      where(filterParam, '<=', filterString + '\uf8ff'),
    )

    if (filterParam === 'address') {
      const keywordQuery = query(
        collectionGroup(db, EXCEPTION),
        where("addressKeywords", 'array-contains', filterString.toLowerCase()),
      )
      const keywordSnapshot = await getDocs(keywordQuery)
      keywordSnapshot.forEach((doc) => {
        dataIDs.add(doc.id)
        filteredCustomer.push({ id: doc.id, ...doc.data() })
      })
    }

    const customerSnapshot = await getDocs(customerQuery)
    customerSnapshot.forEach((doc) => {
      if (!dataIDs.has(doc.id)) {
        filteredCustomer.push({ id: doc.id, ...doc.data() })
      }
    })

    setCustomers(filteredCustomer)
    setLoading(true);
  }

  function handleExceptionFilter(exception: string) {
    setFilterString(() => exception);
  }

  useEffect(() => {
    if(filterParam == 'exception' && filterString.length) {
      handleFilter();
    }
  }, [filterParam, filterString])

  return (
    <div className="search-body">
      <Form className="d-flex search-form">
        <Dropdown>
          <Dropdown.Toggle
            variant="primary"
            id="dropdown-basic"
            className="search-param"
          >
            {filterParam}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setFilterParam('address')}>
              Address
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterParam('route')}>
              Route
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setFilterParam('exception')}>
              Exception
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Form.Control
          type="search"
          placeholder="Search"
          className="me-2 search-input"
          aria-label="Search"
          onChange={(e) => setFilterString(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleFilter()
            }
          }}
        />
        <Button onClick={handleFilter} variant="outline-primary">
          Search
        </Button>
      </Form>

      {filterParam === 'exception' && (
        <div className="exception-buttons">
          <Button className='exception-button' onClick={() => handleExceptionFilter("contamination")}>CONTAMINATED</Button>
          <Button className='exception-button' onClick={() => handleExceptionFilter("extra")}>EXTRA</Button>
          <Button className='exception-button' onClick={() => handleExceptionFilter("abandoned")}>ABANDONED</Button>
        </div>
      )}

    </div>
  )
}
