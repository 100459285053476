import React, { useContext, useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { signInUser } from '../../helpers/auth'
import { StoreContext } from '../../context/stopContext'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { useNavigate } from 'react-router'
import {
  signInWithGoogle,
  createUserInDB,
  handleUserSignIn,
} from '../../helpers/auth'
import './styles.scss'
import * as firebaseui from 'firebaseui'
import { auth } from '../../firebase-config'
import firebase from 'firebase/compat/app'
import 'firebaseui/dist/firebaseui.css'

export const Login = ({ update }: any) => {
  const { state, dispatch } = useContext(StoreContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const addDriverToState = (driver: any) => {
    dispatch({
      type: 'ASSIGN_DRIVER',
      payload: {
        id: driver?.id,
        name: driver?.name,
        route: driver?.routeNumber,
        relief: driver?.reliefDriver,
        email: driver?.email || '',
        role: driver?.role || 'GENERAL',
      },
    })
  }

  const handleSignIn = (user: any) => {
    handleUserSignIn(user)
      .then((driver: any) => {
        addDriverToState(driver)
        update()
        navigate('/')
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (
        authResult: any,
        redirectUrl: any,
      ) {
        handleSignIn(authResult.user)
        return false
      },
      uiShown: function () {
        // The widget is rendered.
        // Hide the loader.
        const loading = document.getElementById('loader')
        if (loading) {
          loading.style.display = 'none'
        }
      },
    },
    signInFlow: 'popup',
    // signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
  }
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(auth)
    ui.start('#firebaseui-auth-container', uiConfig)
  }, [])
  return (
    <div className="login-body">
      <div id="firebaseui-auth-container"></div>
      <div id="loader">Loading...</div>
    </div>
  )
}
