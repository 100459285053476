import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { VIEW_LOGS } from '../../firebase-config'
import "./styles.scss"
import { GeoPoint } from 'firebase/firestore'

export const ViewLog = () => {

    const [viewLogs, setViewLogs] = useState<any[]>([])

    async function fetchViewLogs() {
        const dbData: any[] = []
        const viewLogs = collection(db, VIEW_LOGS)
        getDocs(viewLogs).then((res) => {
            res.forEach((doc) => {
                const log: {
                    [key: string]: any;
                } = { ...doc.data(), docId: doc.id}
                if(log.time !== undefined) dbData.push(log)
            })
        }).then(() => {
            dbData.sort((a, b) => b.time?.seconds - a.time?.seconds)
            setViewLogs(dbData)
        })
    }

    useEffect(() => {
        fetchViewLogs()
    }, [])

    return (
        <Table striped>
            <thead style={{width: "100%"}}>
                <tr>
                    <th className='table-col'>Email</th>
                    <th className='table-col'>Time</th>
                    <th className='table-col'>Address</th>
                </tr>
            </thead>
            <tbody>
                {viewLogs.map((log, i) => {
                    const date = new Date(log.time?.seconds * 1000)
                    return (
                    <tr key={i}>
                        <td className='table-col'>{log.user || ""}</td>
                        <td className='table-col'>{date.toLocaleString()}</td>
                        <td className='table-col'>{log.address || ""}</td>
                    </tr>
                )})}
            </tbody>
        </Table>
    )
}
