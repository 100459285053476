// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getAuth, GoogleAuthProvider, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAKHOpsfDx_WSVa4Nd2ZaInz21b1nsRrgg',
  authDomain: 'trashbook-eeadf.firebaseapp.com',
  databaseURL: 'https://trashbook-eeadf-default-rtdb.firebaseio.com',
  projectId: 'trashbook-eeadf',
  storageBucket: 'trashbook-eeadf.appspot.com',
  messagingSenderId: '782879139729',
  appId: '1:782879139729:web:8c5b824060d03833599c30',
  measurementId: 'G-6CDPSVCCT2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const functions = getFunctions(app)
const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth(app)
const provider = new GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

if (window.location.hostname === 'localhost') {
  console.log('---local database---')
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
  connectAuthEmulator(auth, 'http://localhost:9099')
}

const USERDRIVER = 'USERDRIVER'
const USERSUPERVISOR = 'USERSUPERVISOR'
const EXCEPTION = 'EXCEPTIONS'
const EXCEPTIONPHOTOS = 'EXCEPTIONPHOTOS'
const VIEW_LOGS = 'VIEW_LOGS'

export {
  app,
  functions,
  db,
  storage,
  auth,
  provider,
  analytics,
  EXCEPTION,
  USERDRIVER,
  USERSUPERVISOR,
  EXCEPTIONPHOTOS,
  VIEW_LOGS,
}
