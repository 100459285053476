import React, { useContext } from 'react'
import { DataDisplay } from '../DataDisplay'
import { StoreContext } from '../../context/stopContext'
import { auth } from '../../firebase-config'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Lottie, { LottieRefCurrentProps, } from 'lottie-react'
import backgroundData from './background.json'
import racoonData from './racoon.json'
import TBTitle from '../../images/trashbookTitle.png'
import './styles.scss'
import Background from "../../images/background.png"

export const Home = () => {
  const { state, dispatch } = useContext(StoreContext)

  return (
    <div className="home-body">
      {auth.currentUser ? (
        <DataDisplay />
      ) : (
        <div className="home-logged-out">
          {/* <Lottie animationData={backgroundData} className="lottie-bg" /> */}
          {/* <img src={Background} className='lottie-bg' /> */}

          <div className="home-hero" style={{ backgroundImage: `url(${Background})` }}>
            {/* <img src={TBTitle} alt="trashbook title" className="tb-title" /> */}
            {/* <h1>Welcome to TrashBook!</h1> */}
            {/* <h2>All your trash-tracking needs in one place</h2> */}
            {/* <Lottie animationData={racoonData} loop={false} className="raccoon" /> */}
            <div className="home-button-group">
              <Link to="/login">
                <button className='login-button-blue'>Log In</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
