import React, { useContext, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { Report } from './Report'
import { Login } from './Login'
import { SignUp } from './SignUp'
import { Home } from './Home'
import { DataDisplay } from './DataDisplay'
import { Feedback } from './Feedback'
import { NavBar } from './NavBar'
import { ThemeContext } from '../context/themeContext'
import { auth } from '../firebase-config'
import { ViewLog } from './ViewLog'

export const Main = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)

  const [updateNav, setUpdateNav] = useState(0)

  const handleThemeToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toggleTheme()
  }

  const PrivateRoute = ({ children }: any) => {
    return auth.currentUser ? children : <Navigate to="/" />
  }

  return (
    <Router>
      <div className="home-page">
        <NavBar update={() => setUpdateNav(updateNav + 1)} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/data"
            element={
              <PrivateRoute>
                <DataDisplay />
              </PrivateRoute>
            }
          />
          <Route
            path="/report"
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
          <Route
            path="/viewlog"
            element={
              <PrivateRoute>
                <ViewLog />
              </PrivateRoute>
            }
          />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/view" element={<h1>View</h1>} />
          <Route
            path="/login"
            element={<Login update={() => setUpdateNav(updateNav + 1)} />}
          />
          <Route
            path="/signup"
            element={<Login update={() => setUpdateNav(updateNav + 1)} />}
          />
        </Routes>
      </div>
    </Router>
  )
}
