import React from 'react'
import { Table, Button } from 'react-bootstrap'
import './styles.scss'

export const Feedback = () => {
  return (
    <div className="feedback-body">
      <div className="email-me-section">
        <p>Questions or comments?</p>
        <Button href="mailto:benn925@yahoo.com?subject=Trashbook Feedback">
          Email Me
        </Button>
      </div>
      <Table className="update-table" striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>5/28/2023</td>
            <td>Added customized address searching for each routes locale and fixed bug where photos wouldn't upload</td>
          </tr>
        <tr>
            <td>5/12/2023</td>
            <td>Narrowed bounds for reporting to only allow San Francisco area</td>
          </tr>
          <tr>
            <td>4/23/2023</td>
            <td>Added google sign-in</td>
          </tr>
          <tr>
            <td>4/22/2023</td>
            <td>Added icons and logo for web app and PWA</td>
          </tr>
          <tr>
            <td>4/21/2023</td>
            <td>Update to require log in to read data to avoid web scraping</td>
          </tr>
          <tr>
            <td>4/2/2023</td>
            <td>Fixed issue where address search would show duplicates.</td>
          </tr>
          <tr>
            <td>3/29/2023</td>
            <td>
              Updated initial data query to only pull the latest 30 events. Upon
              search for specific address, route or exception, there is no limit
              currently.
            </td>
          </tr>
          <tr>
            <td>3/28/2023</td>
            <td>
              Updated search algorithm to search by partial address. Older
              reports may not show up but that will be fixed.
            </td>
          </tr>
          <tr>
            <td>3/26/2023</td>
            <td>Added specific time of day to info window</td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
