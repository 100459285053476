import React, {
  ReactNode,
  useReducer,
  createContext,
} from 'react'
// import * as React from 'react';
import { StoreContextType } from '../@types/store'

const getDataState = () => {
  const state = localStorage.getItem('TrashbookState')
  const defaultState = {
    stops: [],
    driver: null,
  }
  return state ? JSON.parse(state) : defaultState
}
const storeState = getDataState()

const StoreContext = createContext<{
  state: StoreContextType
  dispatch: React.Dispatch<any>
}>({
  state: storeState,
  dispatch: () => null,
})

export const driverReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ASSIGN_DRIVER':
      return {
          id: action.payload.id,
          name: action.payload.name,
          route: action.payload.route,
          relief: action.payload.relief,
          email: action.payload.email,
          role: action.payload.role,
          routeCoords: action.payload.routeCoords,
      }
    default:
      return state
  }
}
export const stopReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_STOP':
      return [
          ...state.stops,
          {
            id: action.payload.id,
            address: action.payload.address,
            notes: action.payload.notes,
            pickedup: action.payload.pickedup,
            photos: action.payload.photos,
          },
        ]
    default:
      return state
  }
}

const mainReducer = ({ driver, stops }:any, action: any) => ({
  driver: driverReducer(driver, action),
  stops: stopReducer(stops, action)
})

interface ChildrenProps {
  children?: ReactNode
}

const StoreProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, storeState)

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  )
}

export { StoreContext, StoreProvider }

// export const reducer = (state:any, action:any) => {
//   switch (action.type) {
//     case "SET_CURRENT_USER":
//       return {
//         ...state,
//         currentUser: action.currentUser
//       };
//     case "SET_GYM_MEMBERS":
//       return {
//         ...state,
//         gymMembers: action.gymMembers
//       };
//       case "SET_GYMS":
//         return {
//           ...state,
//           gymsList: action.gymsList
//         };
//     case "LOGIN_STATUS":
//       return {
//         ...state,
//         loggedIn: action.loggedIn
//       };
//     case "COLLAPSE_NAV":
//       return {
//         ...state,
//         navCollapse: action.navCollapse
//       };
//     case "UPDATE_PAYMENT":
//       return {
//         ...state,
//         currentUser: {
//           ...state.currentUser,
//           paymentData: action.paymentData
//         }
//       };
//     case "ADD_PROPERTIES":
//       return {
//         ...state,
//         currentUser: {
//           ...state.currentUser,
//           paymentData: {
//             subscriptionId: "",
//             startDate: 0,
//             lastInvoice: "",
//           },
//         }
//       };
//     default:
//       return state;
//   }
// };
// function useModalReducer(initialContext:any) {
//   return useReducer(reducer, initialContext)
// }
// const StoreProvider = ({
//   value = [],
//   ...props
// }) => {
//   const [state, dispatch] = useModalReducer(storeState);
//   return <StoreContext.Provider value = {
//     [state, dispatch]
//   } {
//     ...props
//   }
//   />;
// };

// export const useStoreContext = () => {
//   return useContext(StoreContext);
// };
