export function permuteForKeywords(list: string[]): string[] {
    const res = new Set<string>();
    for (let word of list) {
        for (let i = 0; i < word.length; i++) {
            for (let j = i + 1; j <= word.length; j++) {
                res.add(word.substring(i, j).toLowerCase());
            }
        }
    }
    return Array.from(res);
}