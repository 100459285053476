import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile,
  signInWithPopup,
  User,
} from 'firebase/auth'
import { db } from '../firebase-config'
import { doc, setDoc, getDoc } from 'firebase/firestore'
import { auth, provider } from '../firebase-config'

function createUser(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user
      createUserInDB(user)
      return { user }
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      return { errorCode, errorMessage }
    })
}

function signInUser(email: string, password: string): any {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user
      return user
    })
    .catch((error) => {
      const errorMessage = error.message
      throw new Error(errorMessage)
    })
}

function signInWithGoogle() {
  return signInWithPopup(auth, provider)
    .then((userCredential) => {
      const user = userCredential.user
      createUserInDB(user)
      return { user }
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      return { errorCode, errorMessage }
    })
}

function getUser() {
  return onAuthStateChanged(auth, (user: any) => {
    if (user) {
      const uid = user.uid
      return uid
    } else {
      return false
    }
  })
}

function signOutUser() {
  return signOut(auth)
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    })
}

function updateUser(args: any) {
  if (auth.currentUser) {
    return updateProfile(auth.currentUser, args)
      .then(() => {
        return 200
      })
      .catch((error) => {
        return 400
      })
  } else return 400
}
async function createUserInDB(user: User) {
  const newUser = {
    routeNumber: '',
    reliefDriver: false,
    name: user.displayName || '',
    id: user.uid || '',
    email: user.email || '',
    role: 'GENERAL',
    routeCoords: {
      north: 37.81455,
      south: 37.66559,
      east: -122.35037,
      west: -122.53961,
    },
  }
  const userRef = doc(db, 'Users', user.uid)
  await setDoc(userRef, newUser)
  return newUser
}

async function handleUserSignIn(user: any) {
  const userRef = doc(db, 'Users', user.uid)
  const userSnap = await getDoc(userRef)
  if (userSnap.data()) {
    const data: any = { ...userSnap.data(), id: user.uid }
    return data
  } else {
    const data = await createUserInDB(user)
    return data
  }
}

export {
  createUser,
  signInUser,
  signInWithGoogle,
  getUser,
  signOutUser,
  updateUser,
  createUserInDB,
  handleUserSignIn
}
